import IBuyerInvoice, { getAmountLeftToPay } from "../serverModel/IBuyerInvoice";
import { SeeInvoiceText } from "../BuyerRecapMessages";
import Link from "factor-lib/Link";
import { dateDiffDays, serverDateDeserialization, userFormatDate } from "factor-lib/utils/dateUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import DeclarePayment from "./DeclarePayment";

interface IInvoiceBuyerStatus {
    text: string;
    cssClass?: string;
}

const NotDueStatus: IInvoiceBuyerStatus = {
    text: 'à échéance prochaine'
};

const overdueStatus = (nbDays: number): IInvoiceBuyerStatus => ({
    text: `En retard de ${nbDays} jours`,
    cssClass: 'is-danger'
});

const PaidStatus: IInvoiceBuyerStatus = {
    text: 'payée',
    cssClass: 'is-primary'
};

const getBuyerStatus = (invoice: IBuyerInvoice): IInvoiceBuyerStatus => {
    if (invoice.completePaid) {
        return PaidStatus;
    } // else

    const today = new Date();
    const dueDate = serverDateDeserialization(invoice.dueDate);

    const overdueDays = dateDiffDays(dueDate, today);
    if (overdueDays > 0) {
        return overdueStatus(overdueDays);
    } // else

    return NotDueStatus;
};

const BuyerInvoiceCardContent = (
    {
        className,
        invoice,
        showPdf,
        displayFullAmount
    }: {
        className?: string;
        invoice: IBuyerInvoice;
        showPdf: (value: boolean) => void;
        displayFullAmount: boolean;
    }
) => {
    const buyerStatus: IInvoiceBuyerStatus = getBuyerStatus(invoice);
    const amountLeftToPay: number = getAmountLeftToPay(invoice);

    return (
        <div className={`${className ?? ''}`} /* id={BuyerInvoiceCardContentId} */>
            <div className={'p-horizontal-container' /* Plus effiace que 'level' pour que les elements reviennent a la ligne */}>
                <div>
                    <h1 style={{fontSize: '1em'} /* TODO */}>
                        <Link id={`buyerInvoiceSeeDetailsTitle-${invoice.id}`}
                              text={`Facture ${invoice.number}`}
                              action={{clickHandler: () => showPdf(true)}} />
                    </h1>
                </div>

                <div>
                    <span className={`tag is-uppercase is-small${!!buyerStatus.cssClass ?` ${buyerStatus.cssClass}` : ''}`}>
                        { buyerStatus.text }
                    </span>
                </div>
            </div>

            <div className='p-size-3' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
                { formatAmount(displayFullAmount ? invoice.amountWoTax + invoice.amountTax : amountLeftToPay) }
            </div>

            <div className={'p-horizontal-container' /* Plus effiace que 'level' pour que les elements reviennent a la ligne */}>
                <span className='is-size-6'>
                    Date d&apos;échéance : {userFormatDate(serverDateDeserialization(invoice.dueDate))}
                </span>

                <div className='p-size-7'>
                    <div className='p-horizontal-container'>
                        { amountLeftToPay > 0 &&
                            <DeclarePayment className='p-margin-right-4'
                                            invoiceId={invoice.id}
                                            amountLeftToPay={amountLeftToPay} />
                        }
                        <Link id={`buyerInvoiceSeeDetails-${invoice.id}`}
                              className='is-hidden-mobile'
                              action={{clickHandler: () => showPdf(true)}}
                              text={SeeInvoiceText} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyerInvoiceCardContent;
