import {ReactNode, useState} from "react";
import {openInNewTabWoOpener} from "./utils/utils.ts";
import Link from "./Link.tsx";
import {QueryKey} from "@tanstack/react-query";
import ReactQueryWrapper from "./reactquery/ReactQueryWrapper.tsx";
import ILoadable from "./ILoadable.ts";
import Loader from "./Loader.tsx";

// Allow the opening in a new tab

const SingleFileVisualizer = (
    {
        visualizerId,
        visualizerText,

        queryKey,
        queryFunc,

        children
    }: {
        visualizerId?: string;
        visualizerText: string;

        queryKey: QueryKey;
        queryFunc: ILoadable<(signal?: AbortSignal) => Promise<string>>;

        // When idling
        children: (request: () => void) => ReactNode;
    }
) => {
    // Like requested
    const [withIdsUrls, setWithIdsUrls] = useState(false);
    // Uniquement un hack pour en local ?
    const [initiallyOpened, setInitiallyOpened] = useState(false);

    // useEffect(() => {
    //     setWithIdsUrls(false);
    //     setInitiallyOpened(false);
    // }, [existingFileName]);

    return (
        withIdsUrls
            ? ( !!queryFunc.loaded
                ?
                    <ReactQueryWrapper<string> queryKey={queryKey}
                                               queryFunc={queryFunc.loaded}
                    >
                        { (fileDownloadUrl: string) => {
                            // Use useEffect ?
                            if (!initiallyOpened) {
                                openInNewTabWoOpener(fileDownloadUrl);
                                setInitiallyOpened(true);
                            }
                            return (
                                <Link id={visualizerId}
                                      text={visualizerText}
                                      action={{
                                          clickHandler: () => openInNewTabWoOpener(fileDownloadUrl)
                                      }} />
                            );
                        }}
                    </ReactQueryWrapper>
                : <Loader />
            ) : children(() => setWithIdsUrls(true))
    );
}

export default SingleFileVisualizer;