import ISeller from "../serverModel/ISeller";
import Link from "factor-lib/Link";

import InfosListTable, {IData} from "factor-lib/Layouts/InfosListTable.tsx";
import {setInCopyVoid} from "factor-lib/utils/utils.ts";
import SingleFileVisualizer from "factor-lib/SingleFileVisualizer.tsx";
import {axiosGet2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import {getBuyerFactorContext} from "../../../contexts/IBuyerFactorContext.ts";
import axiosConfig from "../../../axiosConfig.tsx";
import {useContext} from "react";
import AuthTokenContext from "../../../react-context/AuthTokenContext.tsx";

import './sellersummary.scss';

// const ibanId = 'iban';

const SellerSummary = (
    {
        seller

    }: {
        seller: ISeller;
    }
) => {
    const preIban: IData[] = [
        {name: 'Nom', value: seller.companyName}
    ];
    const postIban: IData[] = [
        { name: 'Contact', value: <>
            <div>{seller.phone}</div>
            <div>{seller.email}</div>
        </> }
    ];

    const DownloadRibFileText = 'Télécharger RIB';

    const RibFileSection = () => {
        const authToken: string = useContext<string | null>(AuthTokenContext)!;
        return (
            <div className='p-margin-left-4'>
                <SingleFileVisualizer visualizerText={DownloadRibFileText}
                                      queryKey={['rib-url']}
                                      queryFunc={{
                                          loaded: (signal?: AbortSignal) =>
                                              axiosGet2<string>(
                                                  `${getBuyerFactorContext().backendBaseUrl}/seller/rib-file-url`,
                                                  axiosConfig(authToken, signal),
                                                  (p) => p,
                                                  defaultSpecificErrorHandlerAsync
                                              )
                                      }}
                >
                    { (request) =>
                        <Link id='downloadRib'
                              action={{
                                  clickHandler: request
                              }}
                              text={DownloadRibFileText}/>
                    }
                </SingleFileVisualizer>
            </div>
        );
    }

    const iban = seller.iban;
    return (
        // TODO : can we inline ?
        <div className='seller-summary'>
            <InfosListTable className='p-full-width'>
                { !!iban ? [
                    ...preIban,
                    {name: 'IBAN', value: <>
                        <span className='iban p-margin-right-6'>
                            {iban}
                        </span>
                        <div className='p-horizontal-center'>
                            <Link id='copyIban'
                                  action={{
                                      clickHandler: () => {
                                          setInCopyVoid(iban);
                                          // copyIban(document.getElementById(ibanId)!);
                                      }
                                  }}
                                  text='Copier'/>
                            {seller.hasSwanRibFile &&
                                <RibFileSection />
                            }
                        </div>
                        </>
                    },
                    ...postIban
                ] : [
                    ...preIban,
                    ...postIban
                ]
                }
            </InfosListTable>
        </div>
    );
};

export default SellerSummary;
