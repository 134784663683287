import {setLibFactorContext} from "factor-lib/contexts/ILibFactorContext.ts";
import ILogger from "factor-lib/services/logger/ILogger.ts";
import {Axios} from "axios";
import CookiesManager from "factor-lib/services/cookies/CookiesManager.ts";
import {setBuyerFactorContext} from "./IBuyerFactorContext.ts";

const setAllContexts = (
    logger: ILogger,
    showUnexpectedError: boolean,
    cookiesManager: () => CookiesManager,
    staticAxios: Axios,
    backendBaseUrl: string
) => {
    setLibFactorContext(logger, showUnexpectedError, staticAxios);
    cookiesManager();
    setBuyerFactorContext(backendBaseUrl);
}

export default setAllContexts;