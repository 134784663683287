import {CSSProperties, ReactNode} from 'react';

import Input3 from "./Input3.tsx";
import DefaultInput from "./DefaultInput";
import InputEnabled from "./InputEnabled";
import IInnerId from "./IInnerId";
import {isEmpty} from "./utils";

export interface IInputEnabled {
    updateInputValue: (newValue: string) => void;
    validateInput: () => string | null;
    tryComplete?: () => void,
    innerId?: IInnerId | null;
    maxLength?: number;
    // autoComplete?: IAutoComplete;
}

const Input = (
    {
        className,
        style,
        inputClassName,
        fieldName,
        inputValue,
        placeholder,
        customError,
        enabled,
        rounded,
        rightSlot
    }: {
        className?: string;
        style?: CSSProperties;
        inputClassName?: string;
        fieldName?: string;
        inputValue: string;
        placeholder?: string;
        customError?: string | null;
        enabled: IInputEnabled | null;
        rounded?: boolean;
        rightSlot?: ReactNode;
    }
) =>
    !!enabled
        ? <InputEnabled className={className}
                        style={style}
                        inputClassName={inputClassName}
                        fieldName={fieldName}
                        inputValue={inputValue}
                        placeholder={placeholder}
                        customError={customError}
                        enabled={enabled}
                        rounded={rounded}
                        rightSlot={rightSlot}/>
        : <Input3 className={className}
                  style={style}
                  fieldName={fieldName}
                  isEmpty={isEmpty(inputValue)}
                  customError={customError}
                  enabled={null}
                  rounded={rounded}
                  rightSlot={rightSlot}>
            <DefaultInput inputClassName={inputClassName}
                          inputValue={inputValue}
                          placeholder={placeholder ?? fieldName}
                          enabled={null} />
        </Input3>


export default Input;
