import {Outlet, useParams} from "react-router-dom";
import {createBrowserRouter} from "react-router-dom";
import BuyerRecap from "./Pages/Main/BuyerRecap";
import BuyerRecapPage from "./Pages/Main/BuyerRecapPage";
import IBuyerRecapProps from "./Pages/Main/IBuyerRecapProps";
import {ROUTE_BUYER_RECAP_URL} from "./Pages/Main/urls";
import testBuyerRecapProps, {testBuyerRecapPath} from "./testBuyerRecapProps";
import ErrorRouterHandler from "factor-lib/Errors/ErrorRouterHandler.tsx";
import AuthTokenContext from "./react-context/AuthTokenContext.tsx";

const buyerTokenParamName = 'buyerToken';

const BuyerRecapPageWrapper = () =>
    (
        // <BuyerRecapPage buyerToken={useParams()[buyerTokenParamName]!}/>;
        <AuthTokenContext.Provider value={useParams()[buyerTokenParamName]!}>
            <BuyerRecapPage />
        </AuthTokenContext.Provider>
    );

const router = (storyBookUI: boolean) => {

    // TODO : error handling

    // TODO : hierarchy, especially when we have multiple Pages

    let routes = [
        {
            path: `${ROUTE_BUYER_RECAP_URL}/:${buyerTokenParamName}`,
            element: <BuyerRecapPageWrapper/>
        }
    ];

    // TODO : remove at some point, use a proper storybook component
    if (storyBookUI) {
        const props: IBuyerRecapProps = testBuyerRecapProps;
        // TODO : can we do reconstruct
        routes = [
            ...routes,
            {
                path: testBuyerRecapPath,
                element: <BuyerRecap buyerId={props.buyerId}
                                     invoices={props.invoices}
                                     buyerName={props.buyerName}
                                     seller={props.seller}
                                     showInvoicePdf={() => {
                                         // TODO
                                         // eslint-disable-next-line @typescript-eslint/no-empty-function
                                     }} />
            }
        ];
    }
    return createBrowserRouter([
        {
            path: '/',
            element: <Outlet/>,
            errorElement: <ErrorRouterHandler/>,
            children: routes
        }
    ]);
}

export default router;