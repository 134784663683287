import Input from '../Inputs/Input';
import {getValidDate} from "./dateUtils";
import {dateDiffDays, numberFormat2Digits} from "../../utils/dateUtils";
import IInnerId from "../Inputs/IInnerId";
import IFailable from "../../IFailable.ts";

export interface IDateInputEnabled {
    updateDateInputValue: (newDateInput: string) => void;
    validateDate: () => string | null;
    innerId?: IInnerId | null;
}

const DATE_INPUT_SEPARATOR = '/';
const DATE_INPUT_PLACEHOLDER = `JJ${DATE_INPUT_SEPARATOR}MM${DATE_INPUT_SEPARATOR}AAAA`;

export const parseDateInput = (dateInput: string | null): Date | null => {
    if (dateInput === null) {
        return null;
    }
    const dateInputParts = dateInput.split(DATE_INPUT_SEPARATOR);

    if (dateInputParts.length !== 3) {
        return null;
    }

    const dayPart = dateInputParts[0];
    const monthPart = dateInputParts[1];
    const yearPart = dateInputParts[2];

    return getValidDate(dayPart, monthPart, yearPart);
};

export const validPassedBirthDate = (
    birthDateInputParsed: Date | null
    // birthDateInput: string
): IFailable<Date> => {
    // const birthDateInputParsed: Date | null = parseDateInput(birthDateInput);
    if (birthDateInputParsed === null) {
        return ({
            errorO: `La date de naissance est invalide`,
            successO: null
        });
    } else if (dateDiffDays(birthDateInputParsed, new Date()) < 0) {
        return ({
            errorO: `La date de naissance doit être dans le passé`,
            successO: null
        });
    }
    return ({
        errorO: null,
        successO: birthDateInputParsed
    });
}

export const isValidDateInput = (dateInput: string) =>
    !!parseDateInput(dateInput);

export const formatDateInput = (dateValue: Date): string => {
    const dayPart = numberFormat2Digits(dateValue.getDate());
    const yearPart = dateValue.getFullYear();
    const monthPart = numberFormat2Digits(dateValue.getMonth() + 1);

    return `${dayPart}${DATE_INPUT_SEPARATOR}${monthPart}${DATE_INPUT_SEPARATOR}${yearPart}`;
};

export const validateDateInputWithinRange = (
    dateInput: string,
    minDate: Date | null,
    maxDate: Date | null
): string | null => {

    const dateInputParsed = parseDateInput(dateInput);

    if (dateInputParsed === null) {
        return 'La date est invalide'; // always verified by the DateInput, but just to be safe
    }

    return validateDateInputWithinRange2(
        dateInputParsed,
        minDate,
        maxDate
    );
}


export const validateDateInputWithinRange2 = (
    dateInputParsed: Date,
    minDate: Date | null,
    maxDate: Date | null
): string | null => {

    if (!!minDate && dateDiffDays(minDate, dateInputParsed) < 0) {
        return `La date doit être après le ${formatDateInput(minDate)}`;
    }

    if (!!maxDate && dateDiffDays(dateInputParsed, maxDate) < 0) {
        return `La date doit être avant le ${formatDateInput(maxDate)}`;
    }

    return null;
};

const DateInput = (
    {
        className,
        inputClassName,
        fieldName,
        dateInputValue,
        enabled,
        rounded
    }: {
        className?: string;
        inputClassName?: string;
        fieldName?: string;
        dateInputValue: string;
        enabled: IDateInputEnabled | null;
        rounded?: boolean;
    }
) =>
    <Input className={className}
           inputClassName={inputClassName}
           fieldName={fieldName}
           inputValue={dateInputValue}
           placeholder={DATE_INPUT_PLACEHOLDER}
           enabled={(!!enabled && ({
                updateInputValue: enabled.updateDateInputValue,
                validateInput: () => !parseDateInput(dateInputValue)
                    ? 'La date est invalide'
                    : enabled.validateDate(),
                innerId: enabled.innerId
            })) || null}
           rounded={rounded} />;

export default DateInput;
