import BuyerRecap from "./BuyerRecap";
import { useContext, useState } from "react";
import IBuyerCoverage from "./serverModel/IBuyerCoverage";
import GlobalMessageContext from "../../react-context/GlobalMessageContext";
import Modal from 'factor-lib/Modal';
import Loader from 'factor-lib/Loader';
import IUrlWithContentType from 'factor-lib/forms/Upload/IUrlWithContentType';
import IBuyerInvoice from "./serverModel/IBuyerInvoice";
import {axiosGet2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import AuthTokenContext from "../../react-context/AuthTokenContext.tsx";
import extractErrorMessage from "factor-lib/Errors/extractErrorMessage.ts";
import axiosConfig from "../../axiosConfig.tsx";
import {getBuyerFactorContext} from "../../contexts/IBuyerFactorContext.ts";

interface IInvoicePdfShow {
    isLoading: boolean;
    urlWithContentType: IUrlWithContentType | null; // when loaded
}

const BuyerRecapLoaded = (
    {
        buyerCoverage
    }: {
        buyerCoverage: IBuyerCoverage;
    }
) => {
    const authToken: string = useContext<string | null>(AuthTokenContext)!;
    const [invoicePdfShowO, setInvoicePdfShowO] = useState<IInvoicePdfShow | null>(null);
    const messageHandler: (message: string) => void =
        useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;
    return (
        <div>
            <BuyerRecap buyerName={buyerCoverage.buyerName}
                        buyerId={buyerCoverage.buyerId}
                        seller={buyerCoverage.seller}
                        invoices={buyerCoverage.invoices}
                        showInvoicePdf={(invoice: IBuyerInvoice) => {
                            setInvoicePdfShowO({
                                isLoading: true,
                                urlWithContentType: null
                            });
                            /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
                            axiosGet2<IUrlWithContentType>(
                                `${getBuyerFactorContext().backendBaseUrl}/invoice/${invoice.id}/pdf-url`,
                                axiosConfig(authToken),
                                (p) => p,
                                defaultSpecificErrorHandlerAsync
                            )
                                .finally(() => setInvoicePdfShowO({
                                    ...invoicePdfShowO!,
                                    isLoading: false
                                }))
                                .then((response: IUrlWithContentType) => setInvoicePdfShowO({
                                    ...invoicePdfShowO!,
                                    urlWithContentType: response
                                }))
                                .catch((e: Error) =>
                                    messageHandler(extractErrorMessage(false, e))
                                );
                        }} />

            { !!invoicePdfShowO &&
                <Modal className='full-height'
                       id='invoiceDetailsModal'
                       maxWidth={null}
                       active={true}
                       fullMaxWidth={false}
                       close={() => setInvoicePdfShowO(null)}>
                    { invoicePdfShowO.isLoading
                        ? <Loader />
                        : !!invoicePdfShowO.urlWithContentType &&
                            <object data={invoicePdfShowO.urlWithContentType.url}
                                    type={invoicePdfShowO.urlWithContentType.contentType}
                                    className='p-full-container'>
                                {/* TODO: Alternative text */}
                            </object>
                    }
                </Modal>
            }
        </div>
    );
}

export default BuyerRecapLoaded;
