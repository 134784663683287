import {KeyboardEvent} from "react";

export default interface IAutoComplete {
    isMenuOpen: boolean;
    openMenu: () => void;
    closeMenu: () => void;

    nextSuggestion: () => void;
    previousSuggestion: () => void;
    selectSuggestion: () => void;
}

export const onKeyDownAutoCompleteMenu = (e: KeyboardEvent<HTMLInputElement>, autoComplete: IAutoComplete): void => {
    if (autoComplete.isMenuOpen) {
        switch (e.key) {
            case 'ArrowUp':
                autoComplete.previousSuggestion();
                break;
            case 'ArrowDown':
                autoComplete.nextSuggestion();
                break;
            case 'Enter':
                autoComplete.selectSuggestion();
                autoComplete.closeMenu();
                break;
            case 'Escape':
                autoComplete.closeMenu();
                break;
        }
    }
}