import {UseMutationResult, UseQueryOptions} from "@tanstack/react-query";
import extractErrorMessage from "../Errors/extractErrorMessage.ts";
import DoNotRetryHandledError from "../Errors/DoNotRetryHandledError.ts";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

// https://medium.com/in-the-weeds/fetch-a-query-only-once-until-page-refresh-using-react-query-a333d00b86ff
export const ReactQueryNoNeedRefresh: Omit<
    // Does not depend on type
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    UseQueryOptions<any>,
    'queryKey' | 'queryFn'
> = ({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: twentyFourHoursInMs,
});

export const ReactQueryNoUnnecessaryRetry: Omit<
    UseQueryOptions<unknown>,
    'queryKey' // | 'queryFn' | 'initialData'
> = ({
    retry: (count: number, error: Error) => {
        if (error instanceof DoNotRetryHandledError) {
            return false;
        }
        return count <= 3;
    }
});


// message for user
export const extractMutationErrorO = <D, P>(mutationResult: UseMutationResult<D, Error, P>): string | null => {
    if (mutationResult.isError) {
        return extractErrorMessage(
            false,
            mutationResult.error
        );
    } // else

    return null;
}

// a subset of UseQueryResult<T>;
export interface IQueryResult<T> {
    isPending: boolean;
    isError: boolean;
    isFetching: boolean;
    isRefetching: boolean;

    data: T | undefined;
    error: Error | null;
}

// message for user
export const extractQueryErrorO = <T>(queryResult: IQueryResult<T>): string | null => {
    if (queryResult.isError) {
        return extractErrorMessage(
            false,
            queryResult.error!
        );
    } // else

    return null;
}