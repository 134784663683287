import {useMutation, UseMutationOptions, UseMutationResult} from "@tanstack/react-query";
import Button, {IBaseButtonProps} from "./Button";
import {ReactElement} from "react";
import {extractMutationErrorO} from "../reactquery/utils.ts";
import IValueWrapper from "../reactquery/IValueWrapper.ts";

export const computeButtonMutationText = <D, P>(
    text: string,
    mutation: UseMutationResult<D, Error, P>
): string =>
    extractMutationErrorO(mutation) ?? text;

export const ButtonMutationResult = <D, P>  (
    {
        buttonProps,
        text,
        children,
        mutationResult,
        valueO
    }: {
        buttonProps?: IBaseButtonProps;
        text: string;
        children?: (button: ReactElement, mutationResult: UseMutationResult<D, Error, P>) => ReactElement;
        mutationResult: UseMutationResult<D, Error, P>;
        valueO: {
            value: P;
        } | null;
    }
) => {
    const childrenNonNull = children ?? ((b) => b);
    return (
        childrenNonNull(
            <Button {...buttonProps}
                    text={computeButtonMutationText(text, mutationResult)}
                    isLoading={mutationResult.isPending}
                    actionO={!!valueO ? (() => mutationResult.mutate(valueO.value)) : null} />,
            mutationResult
        )
    );
}

const ButtonMutation = <D, P>  (
    {
        buttonProps,
        text,
        mutationOptions,
        children,
        valueO
    }: {
        buttonProps?: IBaseButtonProps;
        text: string;
        mutationOptions: UseMutationOptions<D, Error, P>;
        children?: (button: ReactElement, mutationResult: UseMutationResult<D, Error, P>) => ReactElement;
        valueO: IValueWrapper<P> | null;
    }
) => {
    const mutationResult: UseMutationResult<D, Error, P> = useMutation(mutationOptions);

    return (
        <ButtonMutationResult buttonProps={buttonProps}
                              text={text}
                              valueO={valueO}
                              mutationResult={mutationResult}>
            { children }
        </ButtonMutationResult>
    );
}

export default ButtonMutation;
