import {AxiosRequestConfig} from "axios";
import {FunctionBackendTimeoutMilliseconds} from "factor-lib/serverUtils/axiosConfigUtils.ts";

const axiosConfig = (authToken: string, signal?: AbortSignal): AxiosRequestConfig => ({
    timeout: FunctionBackendTimeoutMilliseconds,
    headers: {
        BuyerToken: authToken
    },
    signal
});

export default axiosConfig;