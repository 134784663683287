import {CSSProperties, ReactNode} from "react";

import './input3.scss';

export interface IEnabled3 {
    isFocused: boolean;
    validateInput: () => string | null;
}

// TODO : Looks like most of the complicated stuff is related to validation
// We should probably split, to have a version w/o validation

const InputMain = (
    {
        id,
        className,
        fieldName,
        isEmpty,
        errorMessage,
        children
    }: {
        id?: string;
        className?: string;
        fieldName?: string;
        isEmpty: boolean;
        errorMessage: string | null;
        children: ReactNode;
    }
) =>
    <div id={id} className={`p-padding-left-5 p-padding-right-5 p-full-height${!!className ? ` ${className}` : ''}`}>
        <div className='p-i-m p-full-height'>
            { !isEmpty &&
                <div className='p-empty-flex-align'>
                    <div className='label p-label p-margin-top-9 p-margin-bottom-9'>
                        { !!fieldName &&
                            <span className='p-field-name'>{ fieldName }</span>
                        }
                        { !!errorMessage &&
                            <span className='error-message'>{ ` ${errorMessage}` }</span>
                        }
                    </div>
                </div>
            }

            { children /* ?? <DefaultInput/> */ }

            { !isEmpty &&
                <div className='p-empty-flex-align'/>
            }
        </div>
    </div>;

const Input3 = (
    {
        className,
        style,
        fieldName,
        isEmpty,
        customError,
        enabled,
        rounded,
        rightSlot,
        children
    }: {
        className?: string;
        style?: CSSProperties;
        fieldName?: string;
        isEmpty: boolean;
        customError?: string | null;
        enabled: IEnabled3 | null;
        rounded?: boolean;
        rightSlot?: ReactNode;
        children: ReactNode;
    }
) => {
    const validationError: string | null = ((!!enabled?.isFocused) || isEmpty)
        ? null
        : !!enabled ? enabled.validateInput() : null;

    return (
        !!rightSlot
            ? <div /*disabled={!enabled}*/ style={style} className={`p-input p-i-revert${
                !!className ? ` ${className}` : ''}${
                rounded ? ' p-i-rounded' : ''}${
                (isEmpty /* && !placeHolder */) ? ' is-empty' : ''}${
                (!!enabled?.isFocused) ? ' is-focused' : ''}${
                !validationError ? ' is-valid' : ''
            }`}>
                { rightSlot }
                <InputMain className='p-empty-flex-align'
                           fieldName={fieldName}
                           isEmpty={isEmpty}
                           errorMessage={!!customError ? customError : validationError}
                >
                    { children }
                </InputMain>

            </div> : <div /*disabled={!enabled}*/ style={style} className={`p-input${
                !!className ? ` ${className}` : ''}${
                rounded ? ' p-i-rounded' : ''}${
                (isEmpty /* && !placeHolder */) ? ' is-empty' : ''}${
                (!!enabled?.isFocused) ? ' is-focused' : ''}${
                !validationError ? ' is-valid' : ''
            }`}>
                <InputMain className='p-full-width'
                           fieldName={fieldName}
                           isEmpty={isEmpty}
                           errorMessage={!!customError ? customError : validationError}
                >
                    { children }
                </InputMain>
            </div>
    );
}

export default Input3;