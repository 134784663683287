import {promiseAllAsync} from "../promiseUtils.ts";

export const EURO_CODE = 'EUR';

export const sum = (array: number[]) => array.reduce(
    (agg, n) => agg + n,
    0
);

export const FR_LOCALE = 'fr-FR';

export const setInCopy = (value: string): Promise<void> =>
    navigator.clipboard.writeText(value);

export const setInCopyVoid = (value: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setInCopy(value);
}

export const openInNewTabWoOpener = (url: string) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    // security consideration
    // https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
    if (newTab) {
        newTab.opener = null;
        newTab.focus();
    }
    return newTab;
};

export const openInNewTab = (url: string): WindowProxy | null => {
    const newTab = window.open(url, '_blank');
    newTab?.focus();
    return newTab;
}

// Just an alias ?
export const flatPromiseAsync = promiseAllAsync;