import prodConfigFactory, {ProdHostname, ProdTelemetryBuyerInstrumentationKey} from "factor-lib/envs/prodConfigFactory";
import IBuyerEnvConfig from "./IBuyerEnvConfig";
import Axeptio from "factor-lib/services/cookies/Axeptio.ts";

const ProdConfig: IBuyerEnvConfig = ({
    ...prodConfigFactory(
        ProdTelemetryBuyerInstrumentationKey,
        // 'polite-tree-05e416103.1.azurestaticapps.net' // Do not include http / https
    ),
    backendUrl: 'https://api.recap.dimpl.io', // 'https://factor-prod-buyers-function.azurewebsites.net'
    debug: false,
    cookiesManager:
        () => Axeptio(ProdHostname)
});

export default ProdConfig;
