import IBuyerCoverage from "./serverModel/IBuyerCoverage";
import BuyerRecapLoaded from "./BuyerRecapLoaded";
import Loader from "factor-lib/Loader";
import {useContext} from "react";
import GlobalMessageContext from "../../react-context/GlobalMessageContext";
import slowLoadingMessage from 'factor-lib/serverUtils/slowLoadingMessage';
import AuthTokenContext from "../../react-context/AuthTokenContext.tsx";
import {axiosGet2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import {getBuyerFactorContext} from "../../contexts/IBuyerFactorContext.ts";
import ReactQueryWrapper from "factor-lib/reactquery/ReactQueryWrapper.tsx";
import axiosConfig from "../../axiosConfig.tsx";


export const recapUrl = () =>
    `${getBuyerFactorContext().backendBaseUrl}/recap`;

const BuyerRecapPage = () => {
    const authToken: string = useContext<string | null>(AuthTokenContext)!;
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;

    return (
        <ReactQueryWrapper queryKey={['buyerRecap']}
                           queryFunc={(signal?: AbortSignal) =>
                               axiosGet2<IBuyerCoverage>(
                                   recapUrl(),
                                   axiosConfig(authToken, signal),
                                   (p) => p,
                                   defaultSpecificErrorHandlerAsync
                               )
                           }
                           onLoading={() =>
                               <div className='p-padding-4 p-vertical-center'>
                                   <span className='p-margin-bottom-4'>{ slowLoadingMessage }</span>
                                   <Loader />
                               </div>
                           }
                           onError={(e) => {
                               messageHandler(e);
                               return null;
                           }}
        >
            { (buyerCoverage: IBuyerCoverage) =>
                <BuyerRecapLoaded buyerCoverage={buyerCoverage} />
            }
        </ReactQueryWrapper>
    );
};

export default BuyerRecapPage;
