
import LocalConfigLib from "factor-lib/envs/LocalConfig";
import IBuyerEnvConfig from "./IBuyerEnvConfig";
import AcceptAllCookies from "factor-lib/services/cookies/AcceptAllCookies.ts";

const LocalConfig: IBuyerEnvConfig = ({
    ...LocalConfigLib,
    backendUrl: 'http://localhost:7071',
    cookiesManager:
        () => AcceptAllCookies,
})

export default LocalConfig;