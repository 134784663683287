import CookiesManager from "./CookiesManager";
import IUserCookiesChoices from "./IUserCookiesChoices";

// we do not control this file
/* eslint-disable */

declare global {
    interface Window {
        axeptioSettings?: any;
        _axcb?: any[];
    }
}

// For now, only used for buyer
const Axeptio = (/* clientId: string, */ domain: string): CookiesManager => (/* cookiesVersion: string, */ onAccept: (choices: IUserCookiesChoices) => void)/* : ICookiesManager */ /* => (
    onIntercomAccept: () => void,
    onSegmentAccept: () => void,
    onAppInsightAccept: () => void
) */: void => {

    // TODO : parameters ?
    window.axeptioSettings = {
        clientId: '60707a3f3472a46f897ef2ae', // clientId,
        userCookiesDomain: domain, // 'dimpl.io', // https://developers.axeptio.eu/v/francais/integration/enregistrement-des-choix-utilisateur
        cookiesVersion: 'PROD 1.2 - Webflow + App'
    };

    // tslint:disable-next-line:only-arrow-functions
    (function(d, s: string) {
        const t = (d.getElementsByTagName(s)[0]) as HTMLScriptElement;
        const e = d.createElement(s) as HTMLScriptElement;
        e.async = true;
        // e.src = '//static.axept.io/sdk.js';
        e.src = '//static.axept.io/sdk-slim.js'; // https://developers.axeptio.eu/sdk/integration-du-sdk
        t.parentNode!.insertBefore(e, t);
    })(document, 'script');

    window._axcb = window._axcb || [];
    window._axcb.push((sdk: any) => {
         // Vous pourrez accéder ici aux méthodes et propriétés
         // de l'instance du SDK Axeptio.

        // https://developers.axeptio.eu/site-integration/special-cases-spa-or-react
        sdk.on('consent:saved', () => {
            // console.log('axeptio consent saved !');
            window.location.reload();
        });

        // {"app_insight":true,"hubspot":true,"google_analytics":true,"segment":true,"hotjar":true,"bing":true,"facebook_pixel":true,"google_ads":true,"linkedin":true,"intercom":true}
        sdk.on('cookies:complete', onAccept /* (choices: any) => {
            if (choices.intercom) {
                onIntercomAccept();
            }
            if (choices.segment) {
                onSegmentAccept();
            }
            if (choices.app_insight) {
                onAppInsightAccept();
            }
        } */);
    });
};


export default Axeptio;