import React from 'react';

import GlobalMessageWrapper from "./react-context/GlobalMessageWrapper";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import {createRoot} from "react-dom/client";

import RouterWrapper from "./RouterWrapper";

import {ReactQueryNoUnnecessaryRetry} from "factor-lib/reactquery/utils.ts";

import setAllContextsFromEnv from "./contexts/setAllContextsFromEnv.ts";

// After other imports, otherwise does not work
import './index.scss';

// ensureIsProdNonDebug(config.debug, envName);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            ...ReactQueryNoUnnecessaryRetry,
            staleTime: 10 /* in minutes */ * 60_000,
            // retry: false
            // refetchOnWindowFocus: false
        },
    }
});

const isDebug = setAllContextsFromEnv();

createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            <GlobalMessageWrapper>
                <QueryClientProvider client={queryClient}>
                    <RouterWrapper/>
                    {/*<BrowserRouter>*/}
                    {/*    <BuyerRoutes storyBookUI={currentEnv.storyBookUI}/>*/}
                    {/*</BrowserRouter>*/}
                    { isDebug &&
                        <ReactQueryDevtools initialIsOpen={false} />
                    }
                </QueryClientProvider>
            </GlobalMessageWrapper>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
