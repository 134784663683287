import Modal from "../Modal";
import {ReactElement} from "react";

export const TitledModalContent = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) =>
    <div>
        <div className='p-bold has-text-centered p-padding-5'>
            { title }
        </div>
        <div className='p-horizontal-divider' />
        { children }
    </div>;

const TitledModal = (
    {
        id,
        active,
        close,
        maxWidth,
        fullMaxWidth,
        title,
        children
    }: {
        id?: string;
        active?: boolean;
        close?: () => void; // unset -> mandatory
        maxWidth?: string;
        fullMaxWidth?: boolean;
        title: string;
        children: ReactElement;
    }
) =>
    <Modal id={id}
           active={active}
           close={close}
           maxWidth={maxWidth ?? null}
           fullMaxWidth={fullMaxWidth ?? false}>
        <TitledModalContent title={title}>
            { children }
        </TitledModalContent>
    </Modal>;

export default TitledModal;
