import {ReactElement, ReactNode} from "react";
import {UnexpectedErrorRenderer2} from "../Errors/UnexpectedErrorRenderer.tsx";
import Loader from "../Loader.tsx";
import {extractQueryErrorO, IQueryResult} from "./utils.ts";

export const reactQueryResultProcessor = <T, O>(
    result: IQueryResult<T>,
    onLoading: () => O,
    onError: (userErrorMessage: string) => O,
    onSuccess: (data: T, incrementalLoading: boolean) => O,
    // displayFullError: boolean,
    onReloading?: (oldData: T) => O
): O => {
    if (result.isPending) {
        return onLoading();
    }

    const errorO = extractQueryErrorO(result);

    if (!!errorO) {
        return onError(errorO);
    }

    const data = result.data!

    if (result.isRefetching && !!onReloading) {
        return onReloading(data);
    }

    return onSuccess(data, result.isFetching);
}

export const DefaultUnexpectedErrorMessage = (
    {
        userErrorMessage
    }: {
        userErrorMessage: string;
    }
) =>
    <UnexpectedErrorRenderer2 mainUserErrorMessage={userErrorMessage}
                              extraDetailsO={null}
    />;

const ReactQueryResultWrapper = <T, > (
    {
        result,
        onLoading,
        onError,
        onReloading,
        children
    }: {
        result: IQueryResult<T>;
        onLoading?: () => ReactNode;
        onError?: (e: string) => ReactNode;
        onReloading?: (oldData: T) => ReactElement;
        children: (data: T, incrementalLoading: boolean) => ReactNode;
    }
) =>
    reactQueryResultProcessor<T, ReactNode>(
        result,
        onLoading ?? (() => <Loader/>),
        onError ??
        ((userErrorMessage: string) =>
            <DefaultUnexpectedErrorMessage userErrorMessage={userErrorMessage}/>
        ),
        children,
        // displayFullError,
        onReloading
    );

export default ReactQueryResultWrapper;
