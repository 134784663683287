import CookiesManager from "./CookiesManager";
import IUserCookiesChoices from "./IUserCookiesChoices";

const AcceptAllCookies: CookiesManager = (onAccept: ((choices: IUserCookiesChoices) => void)): void => {
    setTimeout(() => onAccept({
        intercom: true,
    }), 5000 /* Wait for 5 seconds */)
};

export default AcceptAllCookies;
