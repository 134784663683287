import {getEnv, ensureNonNullEnvName, envError} from 'factor-lib/utils/envUtils';
import DevConfig from "./DevConfig";
import DemoConfig from "./DemoConfig";
import ProdConfig from "./ProdConfig";
import IBuyerEnvConfig from "./IBuyerEnvConfig";
import IBuyerEnv from "./IBuyerEnv";
import LocalConfig from "./LocalConfig";

const currentConfigO: IBuyerEnvConfig | null =
    getEnv<IBuyerEnvConfig | null>(
        () => ensureNonNullEnvName(import.meta.env.VITE_ENV_NAME as string | undefined),
        LocalConfig,
        DevConfig,
        DemoConfig,
        ProdConfig
    );

const CurrentConfigEnv: IBuyerEnvConfig = !!currentConfigO ? currentConfigO : envError();

const CurrentEnv: IBuyerEnv = ({
    ...CurrentConfigEnv,
    cookiesManager: CurrentConfigEnv.cookiesManager(),
    logger: CurrentConfigEnv.logger()
});

export default CurrentEnv;
