import {dateFromDMY} from "../../utils/dateUtils.ts";


export const isValidDay = (genericString: string): boolean => {
    const dayNumber = parseInt(genericString, 10);
    return dayNumber > 0 && dayNumber <= 31;
};

export const isValidMonth = (genericString: string): boolean => {
    const monthNumber = parseInt(genericString, 10);
    return monthNumber > 0 && monthNumber <= 12;
};

// TODO
export const isValidYear = (genericString: string): boolean => {
    const yearNumber = parseInt(genericString, 10);
    return yearNumber > 1850 && yearNumber <= 2100;
};

// https://stackoverflow.com/a/1184359
export const daysInMonth = (month: number /* 1-indexed */, year: number): number =>
    new Date(year, month, 0).getDate();


// TODO : real date validation
export const getValidDate = (day: string, month: string, year: string): Date | null => {

    const areInputsValidNumbers =
        isValidDay(day) &&
        isValidMonth(month) &&
        isValidYear(year);

    if (!areInputsValidNumbers) {
        return null;
    }

    const d = parseInt(day);
    const m = parseInt(month);
    const y = parseInt(year);

    const isValid =
        d <= daysInMonth(m, y);

    return isValid ? dateFromDMY(
        d,
        m,
        y
    ) : null;
}

