import Button, {KIND_OUTLINED} from "../Buttons/Button";

const OkCancelRow = (
    {
        className,
        okIdButton,
        okText,
        validateO,
        isLoading,
        cancel
    }: {
        className?: string;
        okIdButton?: string;
        okText: string;
        validateO: (() => void) | null;
        isLoading: boolean; // handled externally
        cancel: () => void;
    }
) =>
    <div className={`level${!!className ? ` ${className}`: ''}`}>
        <div className='level-item'>
            <Button id='Cancel'
                    text='Annuler'
                    kind={KIND_OUTLINED}
                    isLoading={false}
                    actionO={cancel}
            />
        </div>
        <div className='level-item'>
            <Button id={okIdButton}
                    text={okText}
                    isLoading={isLoading}
                    actionO={validateO} />
        </div>
    </div>;

export default OkCancelRow;
