import LogoTopLeft from './LogoTopLeft';
import {CSSProperties, ReactNode} from "react";

import './topbar.scss';

export const TopBarTitleId = "topBarTitleId";

const TopBar = (
    {
        style,
        logoClick,
        leftExtra,
        title,
        rightMenu
    }:
    {
        style?: CSSProperties;
        logoClick?: () => void;
        leftExtra: ReactNode;
        title: string | null;
        rightMenu: ReactNode;
    }
) =>
    <div style={style}
         className='p-topbar level'>
        <div className='level-left p-empty-flex-align'
             style={{position: "relative", zIndex:2}}>
            <LogoTopLeft className='level-item svg-fill-primary'
                         logoClick={logoClick}>
                { !!leftExtra &&
                    <div className='p-padding-left-5'>
                        { leftExtra /* <GoBack action={goBack}/> */ }
                    </div>
                }
            </LogoTopLeft>
        </div>
        { !!title &&
            <div id={TopBarTitleId}
                 style={{position: "absolute"}} className='p-full-width p-both-center'>
                { title }
            </div>
        }
        { !!rightMenu
            ? rightMenu
            : <div className='p-empty-flex-align level-right is-hidden-mobile'
                   style={{position: "relative", zIndex:2}}/>
        }
    </div>;

export default TopBar;
