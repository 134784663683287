import {UseQueryOptions, useQuery, QueryKey} from "@tanstack/react-query";
import {ReactNode} from "react";
import {
    DefaultUnexpectedErrorMessage,
    reactQueryResultProcessor
} from "./ReactQueryResultWrapper.tsx";
import Loader from "../Loader.tsx";

export type IExtraOptions<T> = Omit<
    UseQueryOptions<T>,
    'queryKey' | 'queryFn'
>;

// TODO : rename to simple ?
const ReactQueryWrapper = <T, > (
    {
        queryKey,
        queryFunc,
        onLoading,
        onError,
        children,
        options
    }: {
        queryKey: QueryKey;
        queryFunc: (signal?: AbortSignal) => Promise<T>;
        onLoading?: () => ReactNode; // Can be null
        onError?: (e: string) => ReactNode;
        children: (t: T) => ReactNode;
        options?: IExtraOptions<T>;
    }
): ReactNode =>
{
    const finalOptions: UseQueryOptions<T> = ({
        queryKey,
        queryFn: ({ signal }: {signal?: AbortSignal; }) => queryFunc(signal),
        ...options
    });
    const queryResult = useQuery<T>(finalOptions);
    return (
        reactQueryResultProcessor<T, ReactNode>(
            queryResult,
            onLoading ?? (() => <Loader />),
            onError ?? ((userErrorMessage: string) =>
                        <DefaultUnexpectedErrorMessage userErrorMessage={userErrorMessage}/>),
            children
        )
    );
}

export default ReactQueryWrapper;
