import {isMobileDeviceWindow} from "factor-lib/utils/deviceUtils";
import IIntercom from "factor-lib/services/Intercom/IIntercom";
import IUserCookiesChoices from "factor-lib/services/cookies/IUserCookiesChoices";
import ILogger from "factor-lib/services/logger/ILogger";
import ILoggerLevel from "factor-lib/services/logger/ILoggerLevel.ts";

const handleCookies2 = (
    logger: ILogger,
    cookiesManager: (onAccept: ((choices: IUserCookiesChoices) => void)) => void,
    intercomFactory: () => Promise<IIntercom>
): void => {
    cookiesManager((choices: IUserCookiesChoices): void => {
        logger.trackTrace(ILoggerLevel.Information, "cookies are accepted by user" /* either accepted cookies, either right now, or before */, choices);
        if (choices.intercom) {
            // Fork
            /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
            (async (): Promise<void> => {
                (await intercomFactory()).init(null);
            })();
        }
    });
};

// also setup the cookies
const handleCookies = (
    logger: ILogger,
    cookiesManager: (onAccept: ((choices: IUserCookiesChoices) => void)) => void,
    intercomFactory: () => Promise<IIntercom>
): void => {
    // don't show messenger BUYER_RECAP page, if run on the mobile
    // if so, we don't need cookies at all
    if (!isMobileDeviceWindow()) {
        handleCookies2(
            logger,
            cookiesManager,
            intercomFactory
        );
    }
}


export default handleCookies;
