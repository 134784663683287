import {EURO_CODE, FR_LOCALE} from "./utils";

// Everything related to format. Should we rename the file ?

const DIMPL_DEFAULT_AMOUNT_FORMAT =
    ({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

const DIMPL_NO_CENTS_AMOUNT_FORMAT =
    ({ minimumFractionDigits: 0, maximumFractionDigits: 0 });


export const formatAmountExtended = (amount: number, /* , */ showCents: boolean, currencyCode: string) =>
    (new Intl.NumberFormat(
        FR_LOCALE,
        // (store.state.language as unknown as ILanguageWrapper).language, // Vuex typescript bug !
        {
            style: 'currency',
            currency: currencyCode,
            ...(showCents ? DIMPL_DEFAULT_AMOUNT_FORMAT : DIMPL_NO_CENTS_AMOUNT_FORMAT)
        }
    ))
        .format(Math.floor(amount) / 100);

// euros by default
export const formatAmount = (amount: number/* , currencyCode: string */) =>
    formatAmountExtended(amount, /* currencyCode, */ true, EURO_CODE);

export const formatAmountWoCents = (amount: number/* , currencyCode: string*/ ) =>
    formatAmountExtended(amount, /* currencyCode, */ false, EURO_CODE);

// Not used : everything in euros for now
// export const formatAmountEur = (amount: number): string =>
//     formatAmount(amount/* , 'EUR' */);
