import Input from './Input';

import {trimEverywhere} from "./utils";

import IInnerId from "./IInnerId";

import {CSSProperties} from "react";

export interface IInputEnabledWoValidate {
    updateInputValue: (newValue: string) => void;
    innerId?: IInnerId | null;
    tryComplete?: () => void;
}

export const reduceInputForFloat = (inputStr: string) =>
    trimEverywhere(inputStr).replace(',', '.');

// TODO : should only be used through PInputAmount ?
// TODO : do not allow negative amounts ?
const FLOAT_REGEX = /^-?\d+(\.\d+)?$/;
export const isValidFloat2 = (reducedInputStr: string): boolean =>
    FLOAT_REGEX.test(reducedInputStr);

export const isValidFloat = (inputStr: string): boolean =>
    isValidFloat2(reduceInputForFloat(inputStr));

export const validateAmountError = (amountStr: string): string | null => {
    if (!isValidFloat(amountStr)) {
        return `Le montant n'est pas valide`;
    }
    return null;
};

// TODO : locale ?
export const parseInputFloat2 = (amountInputStrReduced: string): number =>
    parseFloat(amountInputStrReduced);

export const parseInputFloat = (amountInputStr: string): number =>
    parseInputFloat2(reduceInputForFloat(amountInputStr));

export const parseInputAmount = (amountInputStr: string): number =>
    Math.round(parseInputFloat(amountInputStr) * 100);

export const isValidNewAmountInput = (oldValue: number, newInput: string) =>
    isValidFloat(newInput) && (parseInputAmount(newInput) !== oldValue);

export const formatAmountToInput = (amount: number): string =>
    (amount / 100).toString(10);

const InputAmount = (
    {
        style,
        className,
        inputClassName,
        fieldName,
        inputAmountValue,
        placeholder,
        customError,
        enabled,
        rounded
    }: {
        style?: CSSProperties;
        className?: string;
        inputClassName?: string;
        fieldName?: string;
        inputAmountValue: string;
        placeholder?: string;
        customError?: string | null;
        enabled: IInputEnabledWoValidate | null;
        rounded?: boolean
    }
) =>
    <Input style={style}
           className={className}
           inputClassName={inputClassName}
           fieldName={fieldName}
           inputValue={inputAmountValue}
           placeholder={placeholder}
           customError={customError}
           enabled={(!!enabled && ({
                innerId: enabled.innerId,
                updateInputValue: enabled.updateInputValue,
                tryComplete: enabled.tryComplete,
                validateInput: () => validateAmountError(inputAmountValue),
            })) || null}
           rounded={rounded}
           rightSlot={
                <div className='p-both-center p-i-square p-size-4 p-right-background-color p-round-right'>
                    €
                </div>
            } />;

export default InputAmount;

