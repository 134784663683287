import {ReactNode, useEffect} from "react";

const WithAutofocus = (
    {
        id,
        children
    }: {
        id: string;
        children: ReactNode;
    }
) => {
    useEffect(() => {
        document.getElementById(id)!.focus();
    }, [id]);

    return children;
}

export default WithAutofocus;