import {ChangeEvent, ReactElement, useContext, useState} from "react";
import Link from 'factor-lib/Link';
import TitledModal from 'factor-lib/Modals/TitledModal';
import DateInput, {isValidDateInput, parseDateInput} from 'factor-lib/forms/DateInput/DateInput';
import InputAmount, {formatAmountToInput, isValidFloat, parseInputAmount} from 'factor-lib/forms/Inputs/InputAmount';
import {isValidString} from 'factor-lib/forms/Inputs/utils';
import Input from 'factor-lib/forms/Inputs/Input';
import TextOnlyModal from 'factor-lib/Modals/TextOnlyModal';
import AuthTokenContext from "../../../react-context/AuthTokenContext.tsx";
import {axiosPost2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import {getBuyerFactorContext} from "../../../contexts/IBuyerFactorContext.ts";
import axiosConfig from "../../../axiosConfig.tsx";
import OkCancelMutationRow from "factor-lib/Modals/OkCancelMutationRow.tsx";
import {UseMutationOptions} from "@tanstack/react-query";

const Field = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) =>
    <div className='field'>
        <div className='p-margin-bottom-7 p-form-label'>
            { title }
        </div>
        { children }
    </div>

interface IPaymentDeclarationRequest {
    amount: number;
    date: Date;
    label?: string; // Libelle
    comment?: string;
}

const DeclarePaymentModalContent = (
    {
        invoiceId,
        amountLeftToPay,
        complete,
        cancel
    }: {
        invoiceId: string;
        amountLeftToPay: number;
        complete: () => void;
        cancel: () => void;
    }
) => {
    const authToken: string = useContext<string | null>(AuthTokenContext)!;
    const [ amountInput, setAmountInput ] = useState<string>(formatAmountToInput(amountLeftToPay));
    const [ dateInput, setDateInput ] = useState('');
    const [ labelInput, setLabelInput ] = useState('');
    const [ commentInput, setCommentInput ] = useState('');

    const declarePaymentMutationOptions: UseMutationOptions<void, Error, IPaymentDeclarationRequest> =
        ({
            mutationFn: (params2: IPaymentDeclarationRequest) =>
                axiosPost2<void, IPaymentDeclarationRequest>(
                    `${getBuyerFactorContext().backendBaseUrl}/invoice/${invoiceId}/payment`,
                    params2,
                    axiosConfig(authToken),
                    (p) => p,
                    defaultSpecificErrorHandlerAsync,
                    false
                ),
            onSuccess: () => {
                complete();
            }
        });

    return (
        <div className='p-padding-4'>
            <div>
                <Field title='Montant'>
                    <InputAmount fieldName='Montant'
                                 inputAmountValue={amountInput}
                                 enabled={{
                                     updateInputValue: setAmountInput,
                                     innerId: {
                                         value: 'inputAmountId',
                                         autofocus: true
                                     }
                                 }}/>
                </Field>

                <Field title='Date'>
                    <DateInput dateInputValue={dateInput}
                               enabled={{
                                   updateDateInputValue: setDateInput,
                                   validateDate: () => null
                               }} />
                </Field>

                <Field title='Libellé du paiement (optionnel)'>
                    <Input fieldName='Libellé'
                           inputValue={labelInput}
                           enabled={{
                               updateInputValue: setLabelInput,
                               maxLength: 128,
                               validateInput: () => null
                           }} />
                </Field>

                <Field title='Commentaire (optionnel)'>
                    <textarea className='textarea'
                              placeholder='Commentaire'
                              value={commentInput}
                              maxLength={256}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentInput(e.target.value)} />
                </Field>
            </div>
            <OkCancelMutationRow className='p-margin-top-4'
                                 baseText='Signaler'
                                 cancel={cancel}
                                 mutationOptions={declarePaymentMutationOptions}
                                 action={(isValidFloat(amountInput) && isValidDateInput(dateInput)) && parseInputAmount(amountInput) > 0
                                     ? ((mutate) => mutate({
                                         amount: parseInputAmount(amountInput),
                                         date: parseDateInput(dateInput)!,
                                         // skip empty optional strings
                                         label: isValidString(labelInput) ? labelInput : undefined,
                                         comment: isValidString(commentInput) ? commentInput : undefined
                                     }))
                                     : null}
            />
        </div>
    );
}

interface IComplete {
    isComplete: boolean;
}

const DeclarePayment = (
    {
        className,
        invoiceId,
        amountLeftToPay
    }: {
        className?: string;
        invoiceId: string;
        amountLeftToPay: number;
    }
) => {
    const [showModal, setShowModal] = useState<IComplete | null>(null);

    return (
        <div className={className}>
            <Link text='Signaler un paiement'
                  action={{
                      clickHandler: () => setShowModal({ isComplete: false })
                  }} />

            { !!showModal
                ? showModal.isComplete
                    ? <TextOnlyModal close={() => setShowModal(null)}
                                     maxWidth='640px'
                                     fullMaxWidth={false}
                                     message='Merci, nos équipes opérationnelles vont prendre en compte votre déclaration et mettre à jour votre récapitulatif'
                                     buttonsProps={{
                                         actionO: () => setShowModal(null),
                                         isLoading: false,
                                         text: 'Ok'
                                     }}/>
                    : <TitledModal id='unused'
                                   title='Signalez un paiement que vous avez déjà effectué'
                                   close={() => setShowModal(null)}>
                        <DeclarePaymentModalContent invoiceId={invoiceId}
                                                    amountLeftToPay={amountLeftToPay}
                                                    complete={() => setShowModal({isComplete: true})}
                                                    cancel={() => setShowModal(null)}/>
                    </TitledModal>
                : null
            }
        </div>
    );
}

export default DeclarePayment;