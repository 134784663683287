import './infoslisttable.scss';
import {ReactNode} from "react";

export interface IData {
    name: string;
    value: ReactNode;
}

const InfosListTable = (
    {
        className,
        children
    }: {
        className?: string;
        children: IData[];
    }
) =>
    <table className={`p-infos-list-table${!!className ? ` ${className}` : ''}`}>
        <tbody>
            {children.map((d, index) =>
                <tr key={`tr-${index}`}>
                    <th>{d.name}</th>
                    <td>{d.value}</td>
                </tr>
            )}
        </tbody>
    </table>;

export default InfosListTable;