import demoConfigFactory, {DemoHostname} from "factor-lib/envs/demoConfigFactory";
import IBuyerEnvConfig from "./IBuyerEnvConfig";
import Axeptio from "factor-lib/services/cookies/Axeptio.ts";

const DemoConfig: IBuyerEnvConfig = {
    ...demoConfigFactory(
        'f49d1954-b23c-4ff1-baaa-13cd2803c5ad'
    ),
    backendUrl: 'https://factor-demo-buyers-function.azurewebsites.net',

    cookiesManager:
        () => Axeptio(DemoHostname),
};

export default DemoConfig;
