import {computeButtonMutationText} from "../Buttons/ButtonMutation.tsx";
import OkCancelRow from "./OkCancelRow.tsx";
import {UseMutateFunction, useMutation, UseMutationOptions} from "@tanstack/react-query";

const OkCancelMutationRow = <O, I>(
    {
        className,
        okIdButton,
        mutationOptions,
        baseText,
        cancel,
        action
    }: {
        className?: string;
        okIdButton?: string;
        mutationOptions: UseMutationOptions<O, Error, I>;
        baseText: string;
        cancel: () => void;
        action: ((f: UseMutateFunction<O, Error, I>) => void) | null;
    }
) => {
    const mutation = useMutation(mutationOptions);
    return (
        <OkCancelRow className={className}
                     okIdButton={okIdButton}
                     okText={computeButtonMutationText(baseText, mutation)}
                     cancel={cancel}
                     isLoading={mutation.isPending}
                     validateO={
                            !!action
                                ? (() => action(mutation.mutate))
                                : null
                     } />
    );
};

export default OkCancelMutationRow;