import {throwUnknownContextError} from "factor-lib/contexts/ILibFactorContext.ts";

// different from currentEnv, as does not rely on implementations
export default interface IBuyerFactorContext {
    backendBaseUrl: string;
}

let wrapper: IBuyerFactorContext | null = null;

export const setBuyerFactorContext = (
    backendBaseUrl: string
): void => {
    wrapper = ({
        backendBaseUrl
    });
}

export const getBuyerFactorContext = (): IBuyerFactorContext =>
    wrapper ?? throwUnknownContextError();