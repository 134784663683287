import ISeller from "./Pages/Main/serverModel/ISeller";
import IBuyerInvoice from "./Pages/Main/serverModel/IBuyerInvoice";
import IBuyerRecapProps from "./Pages/Main/IBuyerRecapProps";
import { addDays, serverDateSerialization } from 'factor-lib/utils/dateUtils';

export const sellerFixture: ISeller = ({
    companyName: 'Une SARL',
    email: 'unemail@gmail.com',
    phone: '0651085455',
    iban: 'FR7676767676763543689400176',
    hasSwanRibFile: false
});

const invoiceFixtureBuyerRecap: IBuyerInvoice = {
    id: 'id',
    completePaid: false,
    partialAmountPaidByBuyer: 0,
    number: '234',
    amountWoTax: 2500,
    amountTax: 2000,
    dueDate: serverDateSerialization(addDays(new Date(), -1)),
};

export const invoicesFixture: IBuyerInvoice[] = [
    // Due
    {
        ...invoiceFixtureBuyerRecap,
        id: 'I1',
        number: 'normal',
        partialAmountPaidByBuyer: 0,
        completePaid: false
    },
    // Partial paid
    {
        ...invoiceFixtureBuyerRecap,
        id: 'I2',
        number: 'partialPaid',
        partialAmountPaidByBuyer: 100000,
        completePaid: false
    },
    // Not due yet
    {
        ...invoiceFixtureBuyerRecap,
        id: 'I3',
        number: 'notDueYet',
        partialAmountPaidByBuyer: 0,
        completePaid: false,
        dueDate: serverDateSerialization(addDays(new Date(), 5))
    },
    // Complete paid
    {
        ...invoiceFixtureBuyerRecap,
        id: 'I4',
        number: 'completePaid',
        partialAmountPaidByBuyer: 0,
        completePaid: true
    },
];

const testBuyerRecapProps /* = (routePath: string) */ : IBuyerRecapProps = ({
    buyerName: 'Facebook',
    buyerId: '99717F53-8470-45B4-B61F-24BA1074E4D9',
    seller: sellerFixture,
    invoices: invoicesFixture
});

export const testBuyerRecapPath = 'testRecap';

export default testBuyerRecapProps;
