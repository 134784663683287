import {ensureNonNullEnvName, envError, getEnv} from "factor-lib/utils/envUtils.ts";
import setAllContexts from "./setAllContexts.ts";
import axios from "axios";
import IBuyerEnvConfig from "../env/IBuyerEnvConfig.tsx";
import LocalConfig from "../env/LocalConfig.ts";
import DevConfig from "../env/DevConfig.ts";
import DemoConfig from "../env/DemoConfig.ts";
import ProdConfig from "../env/ProdConfig.ts";
import handleCookies from "../services/handleCookies.ts";
import currentEnv from "../env/CurrentEnv.ts";

const nonTestEnv = (): IBuyerEnvConfig => {
    const currentConfigO: IBuyerEnvConfig | null = getEnv(() => ensureNonNullEnvName(import.meta.env.VITE_ENV_NAME as string | undefined), LocalConfig, DevConfig, DemoConfig, ProdConfig);
    return !!currentConfigO ? currentConfigO : envError();
}

const currentConfigEnv: IBuyerEnvConfig =
    import.meta.env.NODE_ENV !== 'test'
        ? nonTestEnv()
        : null as unknown as IBuyerEnvConfig; // TODO : improve

const setAllContextsFromEnv = () => {
    const logger = currentConfigEnv.logger();
    const debug = currentConfigEnv.debug;
    setAllContexts(
        logger,
        debug,
        currentConfigEnv.cookiesManager,
        axios,
        currentConfigEnv.backendUrl
    );

    handleCookies(
        logger,
        currentEnv.cookiesManager,
        currentEnv.intercom
    );

    return debug;
}

export default setAllContextsFromEnv;