import { ReactComponent as DimplLogo } from '../DimplLogo.svg';
import {ReactNode} from "react";

import './logotopleft.scss';

const LogoTopLeft =
    ({
        className,
        logoClick,
        children
    }: {
        className?: string;
        logoClick?: (() => void);
        children?: ReactNode;
    }) =>
        // TODO : level
        <div className={`${!!className ? `${className} ` : ''}p-horizontal-center p-padding-top-6 p-padding-bottom-6`}>
            <div className={'p-logo' + (!!logoClick ? ' p-clickable' : '')}
                 onClick={logoClick}>
                <DimplLogo />
            </div>
            { !!children &&
                <div className='p-margin-left-5 p-border-left p-padding-left-5'>
                    { children }
                </div>
            }
        </div>;

export default LogoTopLeft;
