import {FormEvent, KeyboardEvent} from "react";
import IAutoComplete, {onKeyDownAutoCompleteMenu} from "./IAutoComplete";
import IInnerId from "./IInnerId.ts";
import WithAutofocus from "./WithAutofocus.tsx";
import {checkTryComplete} from "./utils.ts";

export interface IDefaultInputEnabled {

    innerId?: IInnerId | null;

    updateInputValue: (newValue: string) => void,
    maxLength?: number;
    validateInput: () => string | null;
    tryComplete?: (() => void) | null;

    // isFocused: boolean;
    uponFocus: () => void;
    uponBlur: () => void;

    autoComplete?: IAutoComplete | null;
}

export const DefaultInput2 = (
    {
        inputClassName,
        inputValue,
        placeholder,
        enabled
    }: {
        inputClassName?: string;
        inputValue: string;
        placeholder?: string;
        enabled: IDefaultInputEnabled | null;
    }
) =>
    <input id={enabled?.innerId?.value}
           // Pk le p-full-width ?
           className={`p-full-width p-default-input${!!inputClassName ? (' ' + inputClassName) : ''}${!!enabled ? '' : ' p-input-disabled'}`}
           value={inputValue}
           maxLength={enabled?.maxLength}
           onChange={(!!enabled && ((e: FormEvent<HTMLInputElement>) => {
               // defaultOnChange(enabled, inputValue, e);
               const newValue: string = e.currentTarget.value;
               if (newValue !== inputValue) {
                   enabled.updateInputValue(newValue);
                   /*
                   if (!!enabled.autoComplete && !enabled.autoComplete.isMenuOpen) {
                       enabled.autoComplete.openMenu();
                   } */
                   // if (isEmpty(newValue)) {
                   //     enabled.uponFocus(); // TODO: what ?
                   // }
               }
           })) || undefined}
           onKeyDown={(!!enabled && ((e: KeyboardEvent<HTMLInputElement>) => {
               if (!!enabled.autoComplete) {
                   onKeyDownAutoCompleteMenu(e, enabled.autoComplete);
               }
               checkTryComplete(enabled, e);
           })) || undefined}
           onFocus={(!!enabled && (() => {
               enabled.uponFocus();
           })) || undefined}
           onBlur={(!!enabled && (() => {
               enabled.uponBlur();
           })) || undefined}
           placeholder={placeholder}
           required
           disabled={!enabled}
           type='text' /* TODO : could be more specific */ />;

// Tres pauvre...
const DefaultInput = (
    {
        inputClassName,
        inputValue,
        placeholder,
        enabled
    }: {
        inputClassName?: string;
        inputValue: string;
        placeholder?: string;
        enabled: IDefaultInputEnabled | null;
    }
) =>
    !!enabled?.innerId?.autofocus
        ? <WithAutofocus id={enabled?.innerId.value}>
            <DefaultInput2 inputClassName={inputClassName}
                           inputValue={inputValue}
                           placeholder={placeholder}
                           enabled={enabled}/>
        </WithAutofocus>
        : <DefaultInput2 inputClassName={inputClassName}
                         inputValue={inputValue}
                         placeholder={placeholder}
                         enabled={enabled}
        />;

export default DefaultInput;