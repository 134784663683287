import {CSSProperties, ReactElement, ReactNode, useState} from "react";
import Input3 from "./Input3.tsx";

// Enabled, as only adds focus
const InputEnabled2 = (
    {
        className,
        style,
        fieldName,
        isEmpty,
        customError,
        rounded,
        rightSlot,
        validateInput,
        children
    }: {
        className?: string;
        style?: CSSProperties;
        fieldName?: string;
        isEmpty: boolean;
        customError?: string | null;
        rounded?: boolean;
        rightSlot?: ReactNode;
        validateInput: () => string | null;
        children: (setFocused: (newFocus: boolean) => void) => ReactElement;
    }
) => {
    const [ isFocused, setFocused ] = useState(false);

    // const autofocusInnerId: string | null = enabled.autofocusInnerId;

    return (
        <Input3 className={className}
                style={style}
                fieldName={fieldName}
                isEmpty={isEmpty}
                customError={customError}
                enabled={{
                     validateInput,
                     isFocused
                 }}
                rounded={rounded}
                rightSlot={rightSlot}>
            { children(setFocused) }
        </Input3>
    );
}

export default InputEnabled2;