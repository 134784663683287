import devConfigFactory, {DevTelemetryBuyerInstrumentationKey} from "factor-lib/envs/devConfigFactory";
import IBuyerEnvConfig from "./IBuyerEnvConfig";
import Axeptio from "factor-lib/services/cookies/Axeptio.ts";

const DevConfig: IBuyerEnvConfig = {
    ...devConfigFactory(
        DevTelemetryBuyerInstrumentationKey
    ),

    backendUrl: 'https://factor-dev-buyers-function-2.azurewebsites.net',

    cookiesManager:
        () => Axeptio('jolly-ocean-0a3184803.azurestaticapps.net' /* Do not include http / https*/ ),
};

export default DevConfig;
