import {KeyboardEvent} from "react";

export const isEmpty = (value: string): boolean =>
    value.length === 0;

// TODO : should only be used through Input ?
export const trimEverywhere = (s: string): string => s.replace(/\s+/g, '');

export interface ICompletable {
    tryComplete?: (() => void) | null;
    validateInput: () => string | null;
}

export const checkTryComplete = (enabled: ICompletable, e: KeyboardEvent<HTMLInputElement>) => {
    if (
        !!enabled.tryComplete && e.key === 'Enter' &&
        enabled.validateInput() === null
    ) {
        enabled.tryComplete();
    }
}

export const MandatoryFieldErrorText = 'Ce champ est obligatoire';

export const isValidString = (s: string): boolean => !isEmpty(s.trim());

export const validateMandatoryFieldError = (field: string): string | null => {
    // For now
    if (!isValidString(field)) {
        return MandatoryFieldErrorText;
    }
    return null;
};
