import {CSSProperties, ReactNode} from "react";
import {IInputEnabled} from "./Input";
import DefaultInput, {IDefaultInputEnabled} from "./DefaultInput";
import InputEnabled2 from "./InputEnabled2.tsx";
import {isEmpty} from "./utils";

export const convertDefaultEnabled = (enabled: IInputEnabled, setIsFocused: (newFocus: boolean) => void): IDefaultInputEnabled => ({
    innerId: enabled.innerId,
    updateInputValue: enabled.updateInputValue,
    validateInput: enabled.validateInput,
    tryComplete: (enabled.tryComplete) ?? null,
    uponFocus: () => setIsFocused(true),
    uponBlur: () => setIsFocused(false),
    maxLength: enabled.maxLength,
    // autoComplete: enabled.autoComplete ?? null
});

// TODO : looks very poor
// TODO : rename to default ?
const InputEnabled = (
    {
        className,
        style,
        inputClassName,
        fieldName,
        inputValue,
        placeholder,
        customError,
        rounded,
        rightSlot,
        enabled
    }: {
        className?: string;
        style?: CSSProperties;
        inputClassName?: string;
        fieldName?: string;
        inputValue: string;
        placeholder?: string;
        customError?: string | null;
        rounded?: boolean;
        rightSlot?: ReactNode;
        enabled: IInputEnabled;
    }
) =>
    <InputEnabled2 className={className}
                   style={style}
                   fieldName={fieldName}
                   isEmpty={isEmpty(inputValue)}
                   customError={customError}
                   rounded={rounded}
                   rightSlot={rightSlot}
                   validateInput={enabled.validateInput}>
        { (setIsFocused: (newFocus: boolean) => void) =>
            <DefaultInput inputClassName={inputClassName}
                          inputValue={inputValue ?? ''}
                          placeholder={placeholder ?? fieldName}
                          enabled={convertDefaultEnabled(enabled, setIsFocused)} />
        }
    </InputEnabled2>;

export default InputEnabled;